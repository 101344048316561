import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
`;

const FooterText = styled.p`
  font-family: 'Orbitron', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin: 0;
  color: white;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterText>© 2023 1337 LLC. All Rights Reserved.</FooterText>
    </FooterContainer>
  );
}

export default Footer;