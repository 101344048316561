import React, { useState } from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  min-height: calc(100vh - 100px);
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
`;

const Title = styled.h1`
  font-family: 'Orbitron', sans-serif;
  color: #4ecca3; // Bright teal
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 0 0 10px rgba(78, 204, 163, 0.3);
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background: rgba(30, 39, 66, 0.6);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(78, 204, 163, 0.1);
`;

const Input = styled.input`
  margin-bottom: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #e2e2e2;
  font-family: 'Roboto', sans-serif;
  transition: all 0.3s ease;

  &::placeholder {
    color: rgba(226, 226, 226, 0.5);
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 5px rgba(78, 204, 163, 0.5);
  }
`;

const TextArea = styled.textarea`
  margin-bottom: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #e2e2e2;
  font-family: 'Roboto', sans-serif;
  min-height: 150px;
  transition: all 0.3s ease;

  &::placeholder {
    color: rgba(226, 226, 226, 0.5);
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 5px rgba(78, 204, 163, 0.5);
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4ecca3;
  color: #1a1a2e;
  font-family: 'Orbitron', sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: center;

  &:hover {
    background-color: #45b393;
    box-shadow: 0 0 10px rgba(78, 204, 163, 0.5);
  }
`;

const ContactInfo = styled.div`
  margin-top: 50px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #b8b8b8;

  p {
    margin: 10px 0;
    transition: all 0.3s ease;

    &:hover {
      color: #4ecca3;
    }
  }
`;

function ContactSection() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <ContactContainer>
      <Title>Contact Us</Title>
      <ContactForm onSubmit={handleSubmit}>
        <Input 
          type="text" 
          name="name" 
          placeholder="Your Name" 
          value={formData.name} 
          onChange={handleChange} 
          required 
        />
        <Input 
          type="email" 
          name="email" 
          placeholder="Your Email" 
          value={formData.email} 
          onChange={handleChange} 
          required 
        />
        <TextArea 
          name="message" 
          placeholder="Your Message" 
          value={formData.message} 
          onChange={handleChange} 
          required 
        />
        <SubmitButton type="submit">Send Message</SubmitButton>
      </ContactForm>
      <ContactInfo>
        <p>Email: info@1337.ai</p>
        <p>Phone: +1 (337) 133-7000</p>
        <p>Address: 1337 Quantum Lane, Silicon Valley, CA 94027</p>
      </ContactInfo>
    </ContactContainer>
  );
}

export default ContactSection;