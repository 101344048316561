import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// The logo appears in multiple places on the homepage:
// 1. In the Header component (src/components/Header.js)
// 2. In the HeroSection component (src/components/HeroSection.js, referenced in App.js)
// 3. In the Footer component (src/components/Footer.js)

// The logo file path is defined in App.js as:
// const logo = '/img/logo.svg';

// This path is then passed to the relevant components as a prop.
