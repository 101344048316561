import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  // Remove the background-color property
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  max-height: 40px;
  width: auto;
  display: block;
  filter: brightness(0) invert(1); /* This will make the logo white */
`;

const NavToggle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
`;

const NavToggleLine = styled.span`
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s linear;
`;

function Header({ toggleNavigation, logo }) {
  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <LogoImage 
            src={logo} 
            alt="1337.AI"
          />
        </Link>
      </LogoContainer>
      <NavToggle onClick={toggleNavigation}>
        <NavToggleLine />
        <NavToggleLine />
        <NavToggleLine />
      </NavToggle>
    </HeaderContainer>
  );
}

export default Header;