import React from 'react';
import { Link } from 'react-router-dom';

function AboutSection() {
  return (
    <section className="about pt-100 pb-20">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="main-lead mb-80">
              <h3 className="mb-10">What we do.</h3>
              <p>Our clients include fashion brands, liquor companies, and NGOs in the United States and Europe.<br />Contact us to find out more.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container inline-content split-container">
        <div className="row">
          <div className="col-md-8 img-content radius">
            <div className="img-block">
              <div className="background-img">
                <img alt="1337.AI" src="/img/site/1.jpg" />
              </div>
            </div>
          </div>
          <div className="col-md-7 col-md-push-5 intro-content push-right">
            <div className="inner-content">
              <h2 className="mb-10">Services.</h2>
              <p>We develop custom in-house AI solutions for select clientele.<br /><br />Services we offer:<br />Custom Diffusion Models<br />Private AI Chatbots<br />Fine-tuned LLMs<br />Deepfake Video Production<br />AI Photography<br />Consultation Services</p>
              <Link className="plus-btn" to="/about"><i className="icon-plus"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;