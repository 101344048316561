import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeroContainer = styled.section`
  position: relative;
  height: calc(100vh - 60px); // Subtract footer height
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const Logo = styled.img`
  max-width: 150px;
  height: auto;
`;

const ContentContainer = styled.div`
  text-align: center;
  padding: 0 20px;
  max-width: 600px;
  width: 100%;
`;

const TextBox = styled.div`
  background-color: rgba(22, 33, 62, 0.7); // Lighter, semi-transparent background
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(78, 204, 163, 0.2);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'Orbitron', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #4ecca3; // Keeping the teal color for the title

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1rem;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  color: #e2e2e2; // Light gray for better readability

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  border: 2px solid #4ecca3;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
  color: #4ecca3;
  text-decoration: none;
  font-family: 'Orbitron', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background-color: #4ecca3;
    color: #16213e;
  }
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      <VideoBackground autoPlay loop muted playsInline>
        <source src="/img/1337.mp4" type="video/mp4" />
      </VideoBackground>
      <LogoContainer>
        <Logo src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="1337.AI Logo" />
      </LogoContainer>
      <ContentContainer>
        <TextBox>
          <Title>
            1337 <span style={{color: '#ffffff'}}>Intelligence</span>
          </Title>
          <Subtitle>Empowering clients with AI solutions</Subtitle>
          <StyledLink to="/demos">
            Explore Our Demos
          </StyledLink>
        </TextBox>
      </ContentContainer>
    </HeroContainer>
  );
};

export default HeroSection;