import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transition: opacity 0.5s ease, visibility 0.5s ease; // Increased duration
  z-index: 999;
`;

const NavContainer = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #16213e; // Deep blue color
  transform: translateX(${props => props.isOpen ? '0' : '100%'});
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1); // Slower, eased transition
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
`;

const NavHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
`;

const NavLogo = styled.img`
  max-height: 60px;
  width: auto;
  display: block;
  margin: 20px auto;
  max-width: 80%;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #4ecca3; // Matching teal color from contact page
  font-size: 24px;
  cursor: pointer;
  font-family: 'Orbitron', sans-serif;
  transition: color 0.3s ease;

  &:hover {
    color: #45b393;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 50px;
`;

const NavItem = styled.li`
  margin-bottom: 20px;
  opacity: ${props => props.isOpen ? 1 : 0};
  transform: translateX(${props => props.isOpen ? '0' : '20px'});
  transition: opacity 0.5s ease, transform 0.5s ease; // Increased duration
  transition-delay: ${props => props.delay}s;
`;

const NavLinkStyles = `
  color: #e2e2e2;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Orbitron', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  display: block;
  padding: 5px 0;
  transition: all 0.3s ease;

  &:hover {
    color: #4ecca3;
    text-shadow: 0 0 10px rgba(78, 204, 163, 0.5);
    transform: translateX(5px);
  }
`;

const NavLink = styled(Link)`
  ${NavLinkStyles}
`;

const ExternalLink = styled.a`
  ${NavLinkStyles}
`;

function Navigation({ isOpen, onClose, logo }) {
  return (
    <>
      <NavOverlay isOpen={isOpen} onClick={onClose} />
      <NavContainer isOpen={isOpen}>
        <NavHeader>
          <NavLogo src={logo} alt="1337.AI" />
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </NavHeader>
        <nav>
          <NavList>
            <NavItem isOpen={isOpen} delay={0.1}>
              <NavLink to="/" onClick={onClose}>HOME</NavLink>
            </NavItem>
            <NavItem isOpen={isOpen} delay={0.2}>
              <NavLink to="/demos" onClick={onClose}>AI VIDEOS</NavLink>
            </NavItem>
            <NavItem isOpen={isOpen} delay={0.3}>
              <ExternalLink href="https://cms.1337.ai" target="_blank" rel="noopener noreferrer">CMS</ExternalLink>
            </NavItem>
            <NavItem isOpen={isOpen} delay={0.4}>
              <ExternalLink href="https://billing.stripe.com/p/login/aEUeWfbPB8JRbSM8ww" target="_blank" rel="noopener noreferrer">CLIENT PORTAL</ExternalLink>
            </NavItem>
            <NavItem isOpen={isOpen} delay={0.5}>
              <ExternalLink href="https://3r1n.ai" target="_blank" rel="noopener noreferrer">3R1N.AI</ExternalLink>
            </NavItem>
            <NavItem isOpen={isOpen} delay={0.6}>
              <ExternalLink href="https://blog.1337.ai" target="_blank" rel="noopener noreferrer">BLOG</ExternalLink>
            </NavItem>
            <NavItem isOpen={isOpen} delay={0.7}>
              <NavLink to="/contact" onClick={onClose}>CONTACT</NavLink>
            </NavItem>
          </NavList>
        </nav>
      </NavContainer>
    </>
  );
}

export default Navigation;