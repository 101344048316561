import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Header from './components/Header';
import Navigation from './components/Navigation';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import ContactSection from './components/ContactSection';
import DemosSection from './components/DemosSection';
import Footer from './components/Footer';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body, html {
    height: 100%;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
  }

  body {
    background-color: #16213e; // Set a background color that matches your design
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

function App() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavigation = () => {
    setIsNavOpen(!isNavOpen);
  };

  const logoPath = `${process.env.PUBLIC_URL}/img/logo.svg`;

  return (
    <Router>
      <GlobalStyle />
      <AppContainer>
        <Header toggleNavigation={toggleNavigation} logo={logoPath} />
        <Navigation isOpen={isNavOpen} onClose={() => setIsNavOpen(false)} logo={logoPath} />
        <MainContent>
          <Routes>
            <Route path="/" element={<HeroSection />} />
            <Route path="/about" element={<AboutSection />} />
            <Route path="/contact" element={<ContactSection />} />
            <Route path="/demos" element={<DemosSection />} />
          </Routes>
        </MainContent>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;