import React, { useState } from 'react';
import styled from 'styled-components';

const DemosContainer = styled.div`
  padding: 50px 20px;
  background-color: #16213e;
  min-height: calc(100vh - 60px); // Adjust based on your footer height
`;

const Title = styled.h1`
  font-family: 'Orbitron', sans-serif;
  color: #4ecca3;
  text-align: center;
  margin-bottom: 40px;
`;

const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const VideoThumbnail = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  background-image: url(${props => props.thumbnail});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  // border property removed

  &:hover {
    transform: scale(1.05);
  }
`;

const VideoTitle = styled.h3`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 80%;
  max-width: 800px;
  background-color: #16213e;
  border-radius: 10px;
  padding: 10px;
  // border property removed
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const FallbackThumbnail = styled.div`
  background-color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ecf0f1;
  font-family: 'Roboto', sans-serif;
`;

const videos = [
  { 
    id: 1, 
    title: 'Alien',
    thumbnail: 'https://img.youtube.com/vi/dJaFHhr8sfQ/0.jpg', 
    videoUrl: 'https://cms.1337.ai/view?m=AlG2UA22T'
  },
  {
    id: 2,
    title: 'Sveta test',
    thumbnail: 'https://img.youtube.com/vi/_0l9rOO3ut0/0.jpg',
    videoUrl: 'https://cms.1337.ai/view?m=MUP6LAbjO'
  },
  {
    id: 3,
    title: 'Paris Influencer',
    thumbnail: 'https://img.youtube.com/vi/rQqvZu7CCUU/0.jpg',
    videoUrl: 'https://cms.1337.ai/view?m=EGxwjUJTu'
  },
  {
    id: 4,
    title: 'Paris Influencer',
    thumbnail: 'https://img.youtube.com/vi/PCccodQ1mLQ/0.jpg',
    videoUrl: 'https://cms.1337.ai/view?m=u8ECZ1ZE5'
  },
  {
    id: 5,
    title: 'Chateaux',
    thumbnail: 'https://img.youtube.com/vi/VXbGyaBv7r8/0.jpg',
    videoUrl: 'https://cms.1337.ai/view?m=FLZJwJMsi'
  },
  {
    id: 6,
    title: 'Bob',
    thumbnail: `${process.env.PUBLIC_URL}/img/ufo-desert.jpg`,
    videoUrl: 'https://cms.1337.ai/view?m=RND7jzNVh'
  }// Add more videos here as needed
];

const DemosSection = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [failedThumbnails, setFailedThumbnails] = useState({});

  const handleThumbnailError = (videoId) => {
    setFailedThumbnails(prev => ({ ...prev, [videoId]: true }));
  };

  return (
    <DemosContainer>
      <Title>AI VIDEOS</Title>
      <VideoGrid>
        {videos.map((video) => (
          <VideoThumbnail
            key={video.id}
            thumbnail={failedThumbnails[video.id] ? null : video.thumbnail}
            onClick={() => setSelectedVideo(video)}
          >
            {failedThumbnails[video.id] ? (
              <FallbackThumbnail>{video.title}</FallbackThumbnail>
            ) : (
              <img 
                src={video.thumbnail} 
                alt={video.title} 
                onError={() => handleThumbnailError(video.id)}
                style={{ display: 'none' }}
              />
            )}
            <VideoTitle>{video.title}</VideoTitle>
          </VideoThumbnail>
        ))}
      </VideoGrid>
      {selectedVideo && (
        <Modal onClick={() => setSelectedVideo(null)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={() => setSelectedVideo(null)}>&times;</CloseButton>
            <iframe
              width="100%"
              height="450"
              src={`${selectedVideo.videoUrl}?autoplay=1`}
              title={selectedVideo.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </ModalContent>
        </Modal>
      )}
    </DemosContainer>
  );
};

export default DemosSection;